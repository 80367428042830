import React from "react"
import { Link, animateScroll as scroll } from "react-scroll"
import LOGO from "../../images/farmacia_lusitana_white.svg"
import ContactFormOpenButton from "../Button/ContactFormOpenButton"

export default function Navbar(props) {
  const goHome = () => {
    scroll.scrollToTop()
  }

  return (
    <header className="navbar">
      <div className="main-container">
        <div className="navbar__grid">
          <div className="navbar__grid__logo">
            <button
              className="logo-button"
              onClick={goHome}
              onKeyDown={goHome}
              tabIndex={0}
            >
              <img src={LOGO} alt="logo" draggable="false" />
            </button>
          </div>
          <nav className="navbar__grid__nav">
            <Link
              to="servicos"
              smooth={true}
              duration={500}
              offset={-120}
              onClick={props.s}
            >
              Serviços
            </Link>
            <Link
              to="equipa"
              smooth={true}
              duration={500}
              offset={-120}
              onClick={props.t}
            >
              Equipa
            </Link>
            <Link
              to="horario-contactos"
              smooth={true}
              duration={500}
              offset={-120}
              onClick={props.c}
            >
              Horário
            </Link>
            <Link
              to="horario-contactos"
              smooth={true}
              duration={500}
              offset={-120}
              onClick={props.c}
            >
              Contactos
            </Link>
            <Link
              to="encomendar"
              smooth={true}
              duration={500}
              offset={-80}
              className="contact-form-open-button"
              onClick={props.c}
            >
              Encomendar
            </Link>
            {/* <ContactFormOpenButton text="Encomendar" /> */}
          </nav>
        </div>
      </div>
    </header>
  )
}
